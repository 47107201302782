<template>
    <b-modal ref="modal_atendimentos_pendentes"
             :size="modalSize"
             scrollable
             centered
             id="modal_atendimentos_pendentes"
             hide-footer
             title="Atendimentos Pendentes/Ativos"
             @hide="limparModal() ">
        <b-container class="container-graficos-modal app-scroll-custom">

            <center v-if="!graficosCarregados">
                <i class="fas fa-circle-notch fa-spin carregandoOrc mx-2"
                   small>
                </i>
                <label class="carregandoOrc"> Carregando ...</label>
            </center>

            <b-row :class="{'centered': shouldCenterGraficos}" align-v="end">
                <b-col v-for="(dados, index) in graficosDados"
                       :key="index"
                       :cols="colsSize">
                    <div class="grafico">

                        <div v-if="labelsCarregadas"
                             class="titulo-setor mb-3 text-break animate-fade-in">
                            {{ dados.setor }}
                        </div>
                        <grafico-rosca ref="graficoRosca"
                                       :chartData="dados.chartData"
                                       :options="options" />

                        <div v-if="labelsCarregadas"
                             class="legendasContainer mt-2">
                            <div>
                                <div class="retangulo-label mr-1 text-no-wrap" 
                                     style=" background-color: #35A8CA;"></div>
                                <span class="labelsGraficos">
                                    Atendimentos Ativos ({{dados.ativos}})
                                </span>
                            </div>                            
                            
                            <div>
                                <div class="retangulo-label mr-1"
                                     style=" background-color: #CA5735;"></div>
                                <span class="labelsGraficos">
                                    Atendimentos Pendentes ({{dados.pendentes}})
                                </span>
                            </div>

                        </div>

                    </div>

                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
    import { Doughnut } from 'vue-chartjs';
    import axios from 'axios';

    export default {
        components: {
            'grafico-rosca': {
                extends: Doughnut,
                props: ['chartData', 'options'],

                mounted() {
                    this.renderChart(this.chartData, this.options);
                }
            },
        },

        data() {
            return {
                graficosCarregados: false,
                labelsCarregadas: false,
                coresGrafico: ['#35A8CA', '#CA5735'],
                graficosDados: [],
                options: {
                    legend: {
                        display: false,
                        position: "bottom"
                    },
                    responsive: true,
                    maintainAspectRatio: true,
                },
            };
        },

        computed: {
            shouldCenterGraficos() {
                return this.graficosDados.length < 4;
            },
            modalSize() {
                if (this.graficosDados.length < 3) return "md";
                if (this.graficosDados.length < 4) return "lg";
                return "xl";
            },            
            colsSize() {
                if (this.graficosDados.length < 2) return "8";
                if (this.graficosDados.length < 3) return "6";
                if (this.graficosDados.length < 4) return "4";
                return "3";
            }
        },

        methods: {
            iniciarModal() {
                this.$refs['modal_atendimentos_pendentes'].show();
                this.carregarAtendimentosPendentes_Ativos();
            },

            async carregarAtendimentosPendentes_Ativos() {
                try {
                    const response = await axios.get(`/api/setor/MetricasSetor`);

                    this.graficosDados = response.data.map(list => {
                        let labels = [`Atendimentos Ativos`,`Atendimentos Pendentes`]
                        let coresGrafico = this.coresGrafico
                        let data = [
                                list.QuantidadeAtendimentosAtivos,
                                list.QuantidadeAtendimentosPendentes,
                        ];

                        if (list.QuantidadeAtendimentosAtivos + list.QuantidadeAtendimentosPendentes == 0) {
                            labels = ["Sem informação"];
                            coresGrafico = ["#CCC"];
                            data = [100];
                        }
                        return {
                            setor: list.Nome,
                            ativos: list.QuantidadeAtendimentosAtivos,
                            pendentes: list.QuantidadeAtendimentosPendentes,
                            chartData: {
                                labels: labels,
                                datasets: [{
                                    borderWidth: 0,
                                    borderColor: this.coresGrafico,
                                    backgroundColor: coresGrafico,
                                    data: data
                                }],
                            },
                        };
                    });


                    this.graficosCarregados = true;
                    this.labelsCarregadas = true;
                } catch (error) {
                    console.error(error);
                }
            },

            limparModal() {
                this.graficosCarregados = false;
                this.labelsCarregadas = false;
                this.graficosDados = [];
            }
        },
    };
</script>

<style scoped>

    .centered {
        justify-content: center;
    }

    .graficos-container {

        display: flex;
        flex-wrap: wrap;
    }
    .grafico {
        margin: 15px;
        text-align: center;
    }

    .titulo-setor {
        font-weight: bold;
        font-size: 20px;
        color: #2c3e50;
        line-height: 25px;
    }

    .text-break {
        word-wrap: break-word;
        overflow-wrap: break-word;
    }

    .container-graficos-modal {
        max-height: calc(100vh - 185px);
        overflow-x: hidden;
        overflow-y: auto;
    }

    .legendasContainer{
        text-align:center;
    }

    .retangulo-label {
        display:inline-block;
        height:12px;
        width:25px;
    }
     
    .labelsGraficos{
        font-size: 13px;
    }
    /* animação fading in do titulo*/
    .animate-fade-in {
        opacity: 0;
        animation: fadeIn 1s ease-in-out forwards;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

</style>