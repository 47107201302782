<script>
    import { HorizontalBar } from 'vue-chartjs'
    export default {
        extends: HorizontalBar,
        props: {
            usuarios: {
                type: Array || Object,
                defaults: null
            },
            infos: {
                type: Array || Object,
                defaults: null
            }
        },
        data() {
            return {
                chartData: {
                    labels: this.usuarios,
                    datasets: this.infos,
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            },
                            gridLines: {
                                display: true
                            }
                        }],
                        xAxes: [{
                            gridLines: {
                                display: true
                            }
                        }]
                    },
                    legend: {
                        display: false
                    },
                    responsive: true,
                    maintainAspectRatio: false
                },
                relatorioConsulta: '',
                cabecalhoTable: '',
                colorText: '#FFF'
            }
        },
        mounted() {
             this.renderChart(this.chartData, this.options)
        },
        //created() {
        //    this.renderChart(this.chartData, this.options)
        //},
        methods: {
            start: function () {
                this.renderChart(this.chartData, this.options)
            }
        }
    }

</script>